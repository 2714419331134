import React, {Component} from "react";
import {Link} from "react-router-dom";

const PortfolioListContent = [
    {
        image: 'image-1',
        category: 'Development',
        title: 'Natura',
        href: 'https://naturevite.vercel.app'
    },
    {
        image: 'image-3',
        category: 'Development',
        title: 'React Ecommerce',
        href: 'https://ecommercewithreact.vercel.app'
    },
    {
        image: 'image-2',
        category: 'Development',
        title: 'First React Project',
        href: 'https://burger-react-final.vercel.app'
    },
    {
        image: 'image-10',
        category: 'Development',
        title: 'React with Bulma',
        href: 'https://drop-zone-nu.vercel.app'
    },
    {
        image: 'image-14',
        category: 'Development',
        title: 'Nverts NextJS Blog',
        href: 'https://nverts-wtf-with.vercel.app'
    },
    {
        image: 'image-13',
        category: 'Development',
        title: 'NextJS Blog',
        href: 'https://next-js-blog-demo-neon-beta.vercel.app'
    },
    {
        image: 'image-7',
        category: 'Development',
        title: 'Pure CSS Demo',
        href: 'https://navigation-demo.vercel.app'
    },
    {
        image: 'image-12',
        category: 'Development',
        title: 'Tesla Bootstrap CSS Demo',
        href: 'https://teslawebsitedemo.vercel.app'
    },
    {
        image: 'image-11',
        category: 'Development',
        title: 'React FB basic',
        href: 'https://react-web-example-fb-clon.vercel.app'
    },
    {
        image: 'image-4',
        category: 'Development',
        title: 'Dark/Light CSS Demo',
        href: 'https://dark-light-mode-sigma.vercel.app'
    },
    {
        image: 'image-6',
        category: 'Development',
        title: 'ThreeJS Letters Demo',
        href: 'https://threeletters3d.vercel.app'
    },
    {
        image: 'image-15',
        category: 'Development',
        title: 'ThreeJS + WebGL Demo',
        href: 'https://ragesea.vercel.app'
    },
    {
        image: 'image-8',
        category: 'Development',
        title: 'ThreeJS CameraMove + Particles',
        href: 'https://particles-movement.vercel.app'
    },
    {
        image: 'image-9',
        category: 'Development',
        title: 'ThreeJS Portal Demo',
        href: 'https://friendly-euclid-105eef.netlify.app/'
    },
    {
        image: 'image-5',
        category: 'Development',
        title: 'ThreeJS Galaxy',
        href: 'https://galaxy-gen-zeta.vercel.app'
    }
]

class PortfolioList extends Component {
    render() {
        const {column, styevariation} = this.props;
        const list = PortfolioListContent.slice(0, this.props.item);
        return (
            <React.Fragment>
                {list.map((value, index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail ${value.image}`}></div>
                                <div className={`bg-blr-image ${value.image}`}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>{value.category}</p>
                                    <h4><a href={value.href} target="_blank">{value.title}</a></h4>
                                    <div className="portfolio-button">
                                        <a className="rn-btn" href={value.href} target="_blank">View Details</a>
                                    </div>
                                </div>
                            </div>
                            <a className="link-overlay" href={value.href} target='_blank'></a>
                        </div>
                    </div>
                ))}

            </React.Fragment>
        )
    }
}

export default PortfolioList;