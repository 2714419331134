import React, {Component} from "react";
import {FiCloud, FiUsers, FiCode, FiCompass, FiEye, FiAward} from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCode/>,
        title: 'Frontend Development',
        description: 'Creating engaging and responsive web interfaces using React, JavaScript, and CSS.'
    },
    {
        icon: <FiEye/>,
        title: 'Code Review & Quality Assurance',
        description: 'Ensuring high-quality code and maintaining software integrity through rigorous review processes.'
    },
    {
        icon: <FiUsers/>,
        title: 'Effective Communication',
        description: 'Clear and open communication to ensure project success and collaboration.'
    },
    {
        icon: <FiCloud/>,
        title: 'Amazon Web Services (AWS)',
        description: 'Building scalable and efficient cloud solutions with AWS services.'
    },
    {
        icon: <FiCompass/>,
        title: 'AWS Certifications',
        description: 'AWS Certified Cloud Practitioner, Solutions Architect, and Developer.'
    },
    {
        icon: <FiAward />,
        title: 'AWS Consultation',
        description: 'Leveraging AWS expertise to provide strategic cloud consultation and solutions.'
    },
];


class ServiceThree extends Component {
    render() {
        const {column} = this.props;
        const ServiceContent = ServiceList.slice(0, this.props.item);

        return (
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map((val, i) => (
                        <div className={`${column}`} key={i}>
                            <a>
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}

export default ServiceThree;
