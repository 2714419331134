import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component{
    render(){
        let 
        tab1 = "Main skills",
        tab2 = "Certifications",
        tab3 = "Experience",
        tab4 = "Education";
        const { tabStyle } = this.props

        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                        <Tab>{tab4}</Tab>
                                    </TabList>

                                    {/*skills*/}
                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <p>Web and user interface design<span> - Development</span></p>
                                                    Websites, web experiences.
                                                </li>
                                                <li>
                                                    <p>AWS Cloud Solutions <span> - Architecture & Development</span>
                                                    </p> Building scalable and efficient cloud solutions with Amazon Web Services.
                                                </li>
                                                <li>
                                                    <p>Code Review and Quality Assurance</p> Ensuring high-quality code
                                                    and maintaining software integrity through rigorous review processes.
                                                </li>
                                                <li>
                                                    <p>Effective Communication</p> Clear and open communication to ensure project success and collaboration.
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    {/*certifications*/}
                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="https://www.credly.com/badges/94272f76-1904-48b2-95b0-5a4e80473c86/public_url" target="_blank">
                                                       AWS Certified Cloud Practitioner</a>
                                                   Proven foundational knowledge of Amazon Web Services.
                                               </li>
                                               <li>
                                                   <a href="https://www.credly.com/badges/af5312e9-3d2f-4663-a02e-e7b751edb4f5/public_url" target="_blank">
                                                       AWS Certified Solutions Architect - Associate</a> Expertise in AWS architecture and design.
                                               </li>
                                               <li>
                                                   <a href="https://www.credly.com/badges/08e8c1f5-7a5c-46d9-b9b8-8d27f124e8c2/public_url" target="_blank">
                                                       AWS Certified Developer - Associate</a> Proficiency in developing applications on AWS.
                                               </li>

                                           </ul>
                                       </div>
                                    </TabPanel>

                                    {/*Experience*/}

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <p>Software Engineer Fullstack<span> -
                                                        Marinero.mx</span></p> September 2022 - February 2023
                                                </li>
                                                <li>
                                                    <p>Software Engineer FrontEnd<span> -
                                                        Linio Mexico</span></p> June 2017 - April 2019
                                                </li>
                                                <li>
                                                    <p>Microsoft Azure Consultant & Developer<span> -
                                                        Cloud Continuity System Services</span></p> October 2015 - November 2016
                                                </li>
                                                <li>
                                                    <p>Intern Consultant & Software Developer<span> -
                                                        Cloud Continuity System Services</span></p> August 2014 - February 2015
                                                </li>
                                            </ul>
                                        </div>

                                    </TabPanel>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <p>AEC Web Design and Programming<span> - CDI College Montreal</span></p>
                                                    August 2019 – February 2021
                                                </li>
                                                <li>
                                                    <p>Engineering in Project Management<span> - Universidad Panamericana
                                                        Mexico</span></p> July 2014 - July 2015
                                                </li>
                                                <li>
                                                    <p>Information Technology and Intelligent Systems Engineer<span> -
                                                        Universidad Panamericana Mexico</span></p> August 2009 - July 2014
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        );
    }
}

export default TabsTwo;